/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { HeurekaDto } from '../models/HeurekaDto';
import type { HeurekaPatientDetailsDto } from '../models/HeurekaPatientDetailsDto';
import type { HeurekaPatientDto } from '../models/HeurekaPatientDto';
export class HeurekaResourceService {
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns string OK
   * @throws ApiError
   */
  public static prepareRedirectToRevokeHeurekaPortalScreen(
    practiceId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/heureka/practices/{practiceId}/prepare-revoke',
      path: {
        practiceId: practiceId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param practiceId
   * @param pid
   * @param smcCsrfProtection
   * @returns HeurekaPatientDto OK
   * @throws ApiError
   */
  public static getHeurekaPatientByPid(
    practiceId: string,
    pid: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<HeurekaPatientDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/heureka/practices/{practiceId}/patients',
      path: {
        practiceId: practiceId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
      query: {
        pid: pid,
      },
    });
  }
  /**
   * @param practiceId
   * @param smcCsrfProtection
   * @returns HeurekaDto OK
   * @throws ApiError
   */
  public static isHeurekaConnected(practiceId: string, smcCsrfProtection?: any): CancelablePromise<HeurekaDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/heureka/practices/{practiceId}/connectionstate',
      path: {
        practiceId: practiceId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
  /**
   * @param patientId
   * @param smcCsrfProtection
   * @returns HeurekaPatientDetailsDto OK
   * @throws ApiError
   */
  public static getHeurekaPatient(
    patientId: string,
    smcCsrfProtection?: any,
  ): CancelablePromise<HeurekaPatientDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/heureka/patients/{patientId}',
      path: {
        patientId: patientId,
      },
      headers: {
        'SMC-CSRF-PROTECTION': smcCsrfProtection,
      },
    });
  }
}

import { Alert, Button, Icons, Typography, buttonClasses, styled } from '@healthinal/ui';
import { parseISO } from 'date-fns';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useUnexpiredNonRevokedDeputiesQuery } from '../state/api/deputies';
import { whoamiAtom } from '../state/api/whoami';
import { selectedDeputyAtom } from '../state/selectedDeputy';
import { selectedOrganizationAtom } from '../state/selectedOrganization';
import { theme } from '../theme';
import { formatDate } from '../utils/date';

const Banner = styled('div')`
  background-color: ${theme.vars.palette.neutral.softBg};
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
`;

const DeputyAlert = styled(Alert)`
  max-width: 1200px;
  width: 100%;
  background-color: transparent;
  padding: unset;
`;

export function NavigationDeputyBanner() {
  const [_, setSelectedOrganization] = useAtom(selectedOrganizationAtom);
  const [{ data: whoami }] = useAtom(whoamiAtom);
  const [selectedDeputy] = useAtom(selectedDeputyAtom);
  const navigate = useNavigate();

  const handleBackToPractice = () => {
    setSelectedOrganization(whoami.practices[0]);
    navigate('/');
  };

  if (selectedDeputy) {
    return (
      <Alert
        variant={'soft'}
        color={'primary'}
        startDecorator={<Icons.Visibility />}
        sx={{ height: 40 }}
        endDecorator={
          <AlertButton variant={'plain'} endDecorator={<Icons.ArrowForward />} onClick={() => handleBackToPractice()}>
            <Typography
              sx={{
                fontSize: 'sm',
                backgroundColor: 'transparent',
              }}
              variant={'soft'}>
              Zurück zu meiner Praxis
            </Typography>
          </AlertButton>
        }>
        Stellvertretung aktiv: Sie haben bis zum {formatDate(parseISO(selectedDeputy.endDate))} Lesezugriff auf diese
        Praxis
      </Alert>
    );
  }
}

export function DeputyBanner() {
  const [_, setSelectedOrganization] = useAtom(selectedOrganizationAtom);
  const [{ data: whoami }] = useAtom(whoamiAtom);
  const [selectedDeputy] = useAtom(selectedDeputyAtom);

  if (!selectedDeputy && whoami.deputies.length > 0) {
    return (
      <>
        {whoami.deputies.map((deputy) => (
          <Banner key={deputy.id}>
            <DeputyAlert
              variant={'plain'}
              startDecorator={<Icons.Visibility />}
              endDecorator={
                <AlertButton
                  variant={'plain'}
                  color={'neutral'}
                  endDecorator={<Icons.ArrowForward />}
                  onClick={() => setSelectedOrganization(deputy.practice)}>
                  Zu {deputy.practice.name} wechseln
                </AlertButton>
              }>
              Stellvertretung aktiv: Sie haben bis zum {formatDate(parseISO(deputy.endDate))} Lesezugriff auf{' '}
              {deputy.practice.name}
            </DeputyAlert>
          </Banner>
        ))}
      </>
    );
  }
  return null;
}

export function OfferingDeputyBanner() {
  const [selectedOrganization] = useAtom(selectedOrganizationAtom);
  const { data: deputies } = useUnexpiredNonRevokedDeputiesQuery(selectedOrganization.id);
  const acceptedDeputies = deputies?.filter((deputy) => deputy.status === 'ACCEPTED');
  const navigate = useNavigate();

  if (acceptedDeputies && acceptedDeputies.length > 0) {
    return (
      <Banner>
        <DeputyAlert
          variant={'plain'}
          startDecorator={<Icons.Visibility />}
          endDecorator={
            <AlertButton
              variant={'plain'}
              color={'neutral'}
              endDecorator={<Icons.ArrowForward />}
              onClick={() => navigate('/settings')}>
              Stellvertretungen verwalten
            </AlertButton>
          }>
          {acceptedDeputies.length > 1 ? (
            <>Stellvertretungen aktiv: {acceptedDeputies.length} Benutzer haben zurzeit</>
          ) : (
            <>
              Stellvertretung aktiv: {acceptedDeputies[0].deputyUser.firstName}{' '}
              {acceptedDeputies[0].deputyUser.lastName} hat bis zum {formatDate(parseISO(acceptedDeputies[0].endDate))}
            </>
          )}{' '}
          Lesezugriff auf diese Praxis
        </DeputyAlert>
      </Banner>
    );
  }
  return null;
}

const AlertButton = styled(Button)(() => ({
  [`&.${buttonClasses.colorPrimary}`]: {
    '--variant-plainHoverBg': 'transparent',
    '--variant-plainActiveBg': 'transparent',
  },
  [`&.${buttonClasses.colorNeutral}`]: {
    '--variant-plainHoverBg': 'transparent',
  },
}));
